$(document).ready(function(){
	// CODES THAT INCLUDE ON LOAD AND RESIZE AT THE SAME TIME
	$('.loader-overlay').show();
	$('.banner-tabs').delay(400).fadeIn(800);
	var resizeTimer;
	$(window).on('load resize', function(e){
		clearTimeout(resizeTimer);

		resizeTimer = setTimeout(function() {

			var $pageHeight = $(window).height(),
			$pageWidth = $(window).width(),
			$navHeight = $('header.main-header').outerHeight(),
			$navHeightScrolled = $('header.main-header.scrolled').outerHeight(),
			$footerHeight = $('footer.footer').outerHeight(),
			$mainWrapper = $('.wrapper-holder');

			if( $mainWrapper.hasClass('not-sticky') ) {
				$('.wrapper-holder').css({
					'min-height': $pageHeight - $navHeight,
					'padding-bottom': $footerHeight
				});
			} else {
				$('.wrapper-holder').css({
					'min-height': $pageHeight,
					'margin-top': 0,
					'padding-bottom': $footerHeight
				});
			}

			// BANNER CONTENT
			$('.front-page .page-banner .carousel .figure .figure-content').css('padding-top', $navHeight);

			$('.destinations-layout .destination-item').each(function(){
				$(this).find('.hover-effect').height( $(this).find('figure').outerHeight() );
			});
			if( Modernizr.mq('(min-width: 1200px)') ){
				$('.home-sect.page-banner .carousel .figure').css({
					'min-height' : $pageHeight
				});
			}
			else if( Modernizr.mq('(max-width: 1199px)') && Modernizr.mq('(min-width: 992px)') ) {
				$('.home-sect.page-banner .carousel .figure').css({
					'min-height' : '500px'
				});
			}
			else if( Modernizr.mq('(max-width: 991px)') && Modernizr.mq('(min-width: 768px)')){
				$('.home-sect.page-banner .carousel .figure').css({
					'min-height' : '400px'
				});
			}
			else{
				$('.home-sect.page-banner .carousel .figure').css({
					'min-height' : '200px'
				});
			}
		}, 250);
	});

	$(window).load(function(){
		AOS.init();
		$('.carousel').carousel({
			pause : false
		});
		// BOOKING LAYOUT
			$('.booking-layout').each(function(){
				$.ajax({
					type : "post",
					dataType : "json",
					url : $('.data-adminlocate').data('ajaxlocate'),
					data : {
						action: "booknow",
						bookingtype: $('.booking-options input[name="txtRadio"]').val(),
						bookingpost: $(this).find('.booking-options').data('trid'),
						reservationid: $(this).find('.booking-options').data('resid')
					},
					success: function(response) {
						// console.log( response.test );
						$('.ldr-sect').delay(400).fadeOut(400);

						// DATE PICKER AND DATE RESERVATION
						dpicker_initialize( response.reserve_dates );

						$('.booking-form-section .booking-form input.txtPayment').val( response.payment_mode );
						$('.booking-form-section .booking-form input.txtReservation').val( response.reservation_id );
						$('.booking-form-section .booking-form select.txtDestinations').html( response.dest );
						$('.booking-form-section .booking-form input.txtDestinationsField').val( response.slctddest );
						$('.booking-form-section .booking-form select.txtDestinations').prop('disabled', 'disabled');

						$('.ldr-sect').delay(400).fadeOut(400);
					}
				});		
				// // ON CHANGE OF PAYMENT
				$(this).find('input[name="txtRadio"]').on('change', function(){
					$('.booking-form-section .booking-form input.txtPayment').val($(this).val());
				});
			});

		var scroll_start = 0;
		var startchange = $('.wrapper-holder');
		var offset = startchange.offset();
		if (startchange.length){
			$(document).scroll(function() { 
				scroll_start = $(this).scrollTop();
				if(scroll_start > offset.top) {
					$('.main-header').removeClass('scrolled');
					$('.main-header').addClass('scrolled');
				} else {
					$('.main-header').removeClass('scrolled');
				}
			});
		}

		$('.loader-overlay').delay(800).fadeOut(500);
	});
});

function dpicker_initialize( $array = [], $dateclass = 'input.datepicker' ){
	$($dateclass).datepicker({
		minDate: 0,
		changeMonth: true,
		changeYear: true,
		beforeShowDay: function(date){
      var string = jQuery.datepicker.formatDate('yy-mm-dd', date);
      return [ $array.indexOf(string) == -1 ]
    }
	});
}